@import '../../global/utils/init';

.view-board-of-directors {
  margin: 0 auto;

  .view-header {
    padding: 0 0 40px;

    p {
      font-size: 22px;
      font-family: $font-franklin-book;
      line-height: 30px;
    }
  }

  .view-content {

    .views-row {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid $dove-gray;
      margin-bottom: 0;
      padding: 35px 0;

      .info {

        .board-title {
          font-size: 30px;
          font-family: $font-franklin-demi;
          line-height: 30px;
        }

        .name {
          color: $steel-blue;
          font-size: 30px;
          font-family: $font-franklin-demi;
          line-height: 30px;
          text-decoration: none;
        }

        .role {
          font-size: 22px;
          font-family: $font-franklin-book;
          line-height: 30px;
          padding-right: 15px;
        }
      }

      &:last-child {
        border-bottom: 1px solid;
      }

      .views-field-field-photo {
        margin: 0;

        .field-content {
          line-height: 0;

          img {
            min-width: 270px;
          }
        }
      }
    }
  }
}

//counsil advisor text block under board of directors

.council-advisor {
  line-height: 48px;

  ~ p {
    font-size: 30px;
    line-height: 30px;
  }
}


.node-profile {

  .field-name-field-photo {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  }
}

@include md {

  .view-board-of-directors {

    .view-header {
      padding: 0 0 25px;
      
      p {
        font-size: 18px;
        line-height: 24px;
      }
    }

    .view-content {

      .views-row {
        flex-direction: column;
        padding: 30px 0;

        .field-content {
          display: flex;

          .info {
            padding: 0 0 20px;

            a {
              font-size: 20px;
              line-height: 22px;
            }

            .name,
            .board-title {
              font-size: 20px;
              line-height: 22px;
            }
          }

          .role {
            font-size: 16px;
            line-height: 22px;
          }
        }

        .views-field-field-photo {
          display: flex;
          justify-content: center;

          img {
            max-width: 210px;
            min-width: unset !important;
          }
        }
      }
    }
  }

  .council-advisor {
    line-height: 24px;
  
    ~ p {
      font-size: 20px;
      line-height: 22px;
    }
  }
}
