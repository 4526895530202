// Import site utilities.
@import '../../global/utils/init';

nav {

  &.tabs {
    box-shadow: 0 2px 1px 1px #80808066;
    margin: 10px 0;
    padding: 0;

    &::before {
      height: 0;
    }

    ul {
      margin-bottom: 0;

      li {

        a {
          line-height: 0;
          padding: 15px;

          &.active {
            line-height: 0;
            padding: 15px;
          }

          &:hover {
            line-height: 0;
            padding: 15px;
          }
        }
      }
    }
  }
}

.user-authentication-page {

  nav {

    &.tabs {
      margin: 130px 0 30px;
    }
  }
}

//User login page 
.user-login {
  margin-bottom: 50px;
}

.tab_wrapper {

  .nav-tabs {
    display: flex;
    justify-content: center;
    list-style-type: none;
    margin: 0 0 100px;
    padding: 0;

    li {
      border: 1px solid $gray;

      a {
        color: $gray;
        display: inline-block;
        font-family: $font-franklin-demi;
        font-size: 24px;
        line-height: 24px;
        padding: 20px 50px;
        text-decoration: none;
        transition: 0.5s;

        &:hover {
          background: $lochmara;
          color: $color-white;
        }
      }

      &.active {
        border-color: $lochmara;

        a {
          background: $lochmara;
          color: $color-white;
        }
      }
    }
  }

  .tab-content {

    a {
      font-family: $font-franklin-demi;
    }

    > .tab-pane {
      display: none;
    }

    > .active {
      display: block;
    }

    .tab-body {
      margin-bottom: 90px;

      p {

        &:last-child {
          margin: 0;
        }
      }
    }
  }
}

@include md {

  nav {

    &.tabs {
      z-index: 0;
    }
  }

  .tab_wrapper {

    .nav-tabs {
      flex-direction: column;
      margin: 0 0 30px;

      li {

        a {
          display: block;
          font-size: 20px;
          padding: 20px;
          text-align: center;
        }
      }
    }

    .tab-content {

      .tab-body {
        margin-bottom: 30px;
      }
    }
  }

  .user-authentication-page {

    nav {
  
      &.tabs {
        margin: 30px 0;
      }
    }
  }
}
