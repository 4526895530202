// Import site utilities.
@import '../../global/utils/init';

.static {

  .block {
    margin: 0;
    padding: 50px 60px;

    .block-title {
      font-size: 30px;
      font-family: $font-franklin-demi;
      line-height: 31px;
    }

    .block-content {
      font-family: $font-franklin-book;
      display: flex;
      flex-direction: column;
      gap: 30px;

      p {
        font-size: 22px;
        line-height: 28px;
      }

      .btn {
        border: 1px solid;
        color: inherit;
        font-size: 24px;
        font-family: $font-franklin-demi;
        line-height: 21px;
        text-decoration: none;
        padding: 20px 40px;
      }
    }
  }
}

@include md {

  .static {

    .block {
      padding: 36px 20px;

      .block--inner-wrapper {
        padding: 0;
        margin: 0;

        .block-title {
          font-size: 22px;
          line-height: 31px;
        }

        .block-content {
          font-size: 16px;
          line-height: 22px;

          .btn {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }
}
