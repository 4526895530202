// Import site utilities.
@import '../../global/utils/init';

//pagination global design

.pager {
  margin-top: 40px !important;
  margin-bottom: 0 !important;

  .pager-current {
    background-color: $pacific-blue;
    font-family: $font-franklin-demi;
  }

  li {
    background-color: transparent;
    padding: 0 10px !important;

    a {
      color: $black;
      font-size: 17px;
      font-family: $font-franklin-book;
      line-height: 28px;

      &:hover {
        background-color: transparent;
      }
    }
  }
}

//search page pager design
.search-form {

  ~ .item-list {
    
    .pager {
      margin-bottom: 90px !important;
    }
  }
}


@include md {

  .pager {
    margin: 30px 0 !important;

    li {
      padding: 0 8px !important;

      a {
        font-size: 14px;
      }
    }

    .pager-next,
    .pager-last,
    .pager-first,
    .pager-previous {

      a {
        text-indent: 0;
        width: 100%;
        overflow: visible;

        &::before {
          display: none;
        }
      }
    }
    
    .pager-first,
    .pager-previous,
    .pager-last {

      a {

        &::after {
          display: none;
        }
      }
    }
  }

  .search-form {

    ~ .item-list {
      
      .pager {
        margin-bottom: 30px !important;
      }
    }
  }
}
