// Typography
//
// Typography variables.

$font-franklin-demi: 'Franklin Demi', 'Arial', sans-serif;
$font-franklin-book: 'Franklin Book', 'Arial', sans-serif;
$font-franklin-medium: 'Franklin Medium', 'Arial', sans-serif;
$font-franklin-oblique: 'Franklin Oblique', 'Arial', sans-serif;


@mixin font-stack-primary {
  font-family: $font-barlow;
}

@mixin font-stack-secondary {
  font-family: $font-serif;
}

// Font-Weights.
$font-weight-light: 200;
$font-weight-normal: 400;
$font-weight-bold: 700;
$font-italic: italic;

// Heading mixins.
@mixin heading-1-style {
  @include font-stack-secondary;
  font-size: 1.5rem;
  // 28px.
  line-height: 1.16;

  @include breakpoint($bp-sm) {
    font-size: 3rem;
    // 56px.
    line-height: 1.16;
  }
}
