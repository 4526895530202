// Import site utilities.

@import '../../global/utils/init';

.paragraphs-item-multi-item,
.paragraphs-item-mosiac-grid-narrow {

  .field-name-field-block-title {

    .field-item {
      font-size: 48px;
      font-family: $font-franklin-demi;
      font-weight: bold;
      line-height: 48px;
      margin-bottom: 35px;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .paragraphs-items-field-item {

    .field-item {

      &.odd {

        .left-content {
          order: 2 !important;
        }

        .right-content {
          order: 1 !important;
        }
      }

      .paragraphs-item-image-text {
        margin-bottom: 30px;

        .content {
          display: grid;
          grid-template-columns: 50% 50%;

          .left-content {
            order: 1;
            display: flex;
            flex-basis: 100%;

            > * {
              width: 100%;

              .field-items {
                height: 100%;
              }
            }

            .field-name-field-image {
              display: flex;
              flex-basis: 100%;

              .field-items {
                display: flex;
                flex-basis: 100%;

                .field-item {
                  display: flex;
                  flex-basis: 100%;
                }
              }
            }

            img {
              height: 100%;
              min-width: calc(100% + 1px);
              min-height: 450px;
              object-fit: cover;
            }
          }

          .right-content {
            background: $lochmara;
            display: flex;
            flex-direction: column;
            justify-content: center;
            order: 2;
            padding: 60px;
            min-height: 450px;
            gap: 24px;

            .title {
              color: $color-white;
              font-size: 36px;
              font-family: $font-franklin-demi;
              line-height: 32px;
              text-align: left;
              align-self: center;
            }

            .description {
              color: $color-white;
              font-size: 18px;
              font-family: $font-franklin-medium;
              line-height: 24px;

              a {
                color: $cornflower;
                font-family: $font-franklin-demi;
              }

              p {
                font-size: 18px;
                line-height: 28px;
                margin-bottom: 20px;

                &:last-child {
                  margin: 0;
                }
              }

              ul {
                padding-left: 17px;
              }

              ol {
                padding-left: 22px;
              }

              ol,
              ul {

                li {
                  font-size: 22px;
                  line-height: 30px;
                }
              }
            }

            .link {
              text-align: center;
              padding-top: 20px;

              a {
                border: 1px solid $white;
                color: $white;
                display: inline-block;
                font-size: 22px;
                font-family: $font-franklin-demi;
                line-height: 30px;
                padding: 14px 48px;
              }
            }
          }
        }
      }
    }

    .field-name-field-image {

      .field-items {
        line-height: 0;

        .field-item {
          line-height: 0;
        }
      }
    }
  }
}

//Logo grid paragraph design

.paragraphs-item-logo-grid {
  border: 1px solid $gray;
  max-width: 750px;
  margin: 0 auto;

  .paragraphs-item-logo-grid-row {
    margin-bottom: 0;
    min-height: 150px;

    .content {
      display: grid;
      grid-template-columns: 50% 50%;
      place-items: center;
      min-height: 150px;

      .field-item {
        border: none;
      }

      .left-content,
      .right-content {

        p {
          font-size: 18px;
          line-height: 28px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        ol {
          padding-left: 23px;
        }
      }

      .left-content {
        display: flex;
        flex-basis: 100%;
        padding: 20px;

        .left-image {

          img {
            min-width: calc(100% + 1px);
            height: 100%;
            object-fit: cover;
          }
        }

        .left-text {
          font-size: 18px;
          line-height: 28px;
        }
      }

      .right-content {
        display: flex;
        flex-basis: 100%;
        padding: 20px;

        .right-image {

          img {
            min-width: calc(100% + 1px);
            height: 100%;
            object-fit: cover;
          }
        }

        .right-text {
          font-size: 18px;
          line-height: 28px;
        }
      }
    }
  }
}

.paragraphs-item-multi-item {
  margin-left: -120px;
  width: 1200px;
}

.paragraphs-item-mosiac-grid-narrow {

  .paragraphs-items-field-item {

    .field-item {

      .paragraphs-item-image-text {

        .content {

          .left-content {

            img {
              min-height: 360px;
            }
          }

          .right-content {
            min-height: unset;
          }
        }
      }
    }
  }
}

@include xl {

  .paragraphs-item-multi-item,
  .paragraphs-item-mosiac-grid-narrow {
    width: 100%;
    margin-left: 0;
  }
}

@include lg {

  .paragraphs-item-multi-item,
  .paragraphs-item-mosiac-grid-narrow {

    .field-name-field-block-title {

      .field-item {
        font-size: 32px;
        line-height: 45px;
        margin-bottom: 20px;
      }
    }

    .paragraphs-items-field-item {

      .field-item {

        .paragraphs-item-image-text {

          .content {

            .left-content {

              img {
                min-width: 100%;
                min-height: unset;
              }
            }

            .right-content {
              min-height: unset;
              padding: 30px;
              gap: 0;

              .title {
                font-size: 24px;
                line-height: 24px;
              }

              .description {
                margin-top: 15px;
                font-size: 18px;
                line-height: 22px;

                p {
                  font-size: 18px;
                  line-height: 22px;
                }

                ol,
                ul {

                  li {
                    font-size: 18px;
                    line-height: 22px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@include md {

  .paragraphs-item-multi-item,
  .paragraphs-item-mosiac-grid-narrow {

    .field-name-field-block-title {

      .field-item {
        font-size: 22px;
        line-height: 45px;
        margin-bottom: 15px;
      }
    }

    .paragraphs-items-field-item {

      .field-item {

        .paragraphs-item-image-text {

          .content {
            display: block;

            .left-content {

              img {
                min-width: 100%;
                min-height: unset;
              }
            }

            .right-content {
              min-height: unset;
              padding: 20px;
              gap: 0;

              .title {
                font-size: 20px;
                line-height: 22px;
              }

              .description {
                margin-top: 15px;
                font-size: 16px;
                line-height: 22px;

                p {
                  font-size: 16px;
                  line-height: 22px;
                }

                ol,
                ul {

                  li {
                    font-size: 16px;
                    line-height: 22px;
                  }
                }
              }

              .link {
                margin: 20px;

                a {
                  font-size: 18px;
                  line-height: 22px;
                  padding: 12px 35px;
                }
              }
            }
          }
        }
      }
    }
  }

  .paragraphs-item-logo-grid {

    .paragraphs-item-logo-grid-row {

      .content {
        grid-template-columns: 1fr;

        .left-content,
        .right-content {

          p {
            font-size: 16px;
            line-height: 22px;
          }
        }
      }
    }
  }
}
