@import '../../global/utils/init';

//this class given by js

.annual-report-page {

  .paragraphs-item-text-block {

    .content {

      a {
        font-family: $font-franklin-demi;
      }
    }
  }
}

//annual report page second view design
.annual-report {

  .views-row {
    border-top: 1px solid $black;
    padding: 33px 0 47px;
    margin: 0;

    .views-field {
      margin: 0;
    }

    &:last-child {
      border-bottom: 1px solid $black;
    }

    .annual-report-wrapper {
      display: flex;
      justify-content: space-between;

      .left-content {
        flex-basis: calc(100% - 270px);

        a {
          color: $pacific-blue;
          font-family: $font-franklin-demi;
          font-size: 22px;
          line-height: 42px;
        }

        .title {
          font-size: 30px;
          font-family: $font-franklin-demi;
          line-height: 32px;
          padding-bottom: 5px;
        }
      }

      .right-content {
        flex-basis: 270px;

        img {
          margin-top: 7px;
          max-width: 100%;
          min-height: 180px;
        }
      }
    }
  }
}

//annual report first view design

.annual-report-top {

  .views-row {
    margin: 0;

    .views-field {
      margin: 0;
    }
  }

  .annual-report-wrapper {

    img {
      border: 1px solid #929090;
    }

    .right-content {

      a {
        color: $pacific-blue;
        font-family: $font-franklin-demi;
        font-size: 22px;
        line-height: 42px;
      }
    }
  }
}


//newsletters tab pages view design

.newsletter {

  .views-row {
    border-top: 1px solid $black;
    padding: 33px 0;
    margin: 0;

    .views-field {
      margin: 0;
    }

    &:last-child {
      border-bottom: 1px solid $black;
    }

    .newsletter-wrapper {
      display: flex;
      justify-content: space-between;
  
      .left-content {
        flex-basis: calc(100% - 270px);
  
        a {
          color: $pacific-blue;
          font-family: $font-franklin-demi;
          font-size: 22px;
          line-height: 42px;
        }
  
        .title {
          font-size: 30px;
          font-family: $font-franklin-demi;
          line-height: 32px;
          padding-bottom: 5px;
        }
      }
  
      .right-content {
        flex-basis: 270px;
        
        img {
          margin-top: 7px;
          border: 1px solid $dove-gray;
        }
      }
    }
  }
}

@include md {
  
  .annual-report-top {

    .annual-report-wrapper {

      .right-content {

        a {
          font-size: 20px;
          line-height: 22px;
        }
      }
    }
  }

  .annual-report {

    .views-row {
      padding: 25px 0;

      .annual-report-wrapper {
        display: block;

        .left-content {
          padding-bottom: 10px;

          .title {
            font-size: 20px;
            line-height: 22px;
            padding: 0;
          }

          a {
            font-size: 20px;
            line-height: 22px;
          }
        }
      }
    }
  }

  .newsletter {

    .views-row {
      padding: 25px 0;

      .newsletter-wrapper {
        display: block;

        .left-content {

          a {
            font-size: 20px;
            line-height: 22px;
          }
          
          .title {
            font-size: 20px;
            line-height: 22px;
          }
        }

        .right-content {
          padding-top: 10px;
        }
      }
    }
  }
}
