@import '../../global/utils/init';

.node-jobs {

  .content {
    margin-bottom: 100px;

    .field-name-field-teaser-image {
      padding: 0 0 25px;
    }

    .field-name-field-how-to-apply,
    .field-name-field-description {
      margin-bottom: 30px;
    }
  }
}

.view-jobs {

  .views-row {
    border-bottom: 1px solid $gray;
    padding: 25px 0;
    margin-bottom: 0;

    .views-field-field-application-deadline {
      display: inline-block;

      span {
        display: inline-block;
      }
      
      .field-content {
        display: inline-block;
      }
    }

    .views-field-title {
      font-family: $font-franklin-demi;
      font-size: 26px;
      line-height: 30px;

      a {
        color: $pacific-blue;
      }
    }

    &.first {
      border-top: 1px solid $gray;
    }
  }
}

@include md {

  .node-jobs {

    .content {
      margin-bottom: 30px;

      .field-name-field-teaser-image {
        padding: 0 0 15px;
      }
    }
  }

  .view-jobs {

    .views-row {

      .views-field-title {
        font-size: 20px;
        line-height: 22px;
      }
  
      .views-field-field-description {
  
        p {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }
}
