@import '../../global/utils/init';

.view-top-5-fmr-updates {

  .view-content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;

    .views-row {
      display: flex;
      margin-bottom: 0;

      .views-field {
        margin-bottom: 0;
      }

      .views-field-nothing {
        display: flex;

        .field-content {
          display: flex;
          flex-direction: column;

          .top {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            border-top: 8px solid #00000029;
            border-right: 8px solid #00000029;
            padding: 40px 25px 25px;

            .title {
              flex-grow: 1;

              a {
                color: $lochmara;
                font-size: 24px;
                font-family: $font-franklin-demi;
                line-height: 28px;
                text-decoration: none;
              }
            }

            .author {
              padding-top: 25px;
            }

            .author,
            .created {
              font-size: 18px;
              line-height: 24px;
            }
          }

          .bottom {
            flex-grow: 1;
            display: contents;

            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

@include md {

  .view-top-5-fmr-updates {

    .view-content {
      grid-template-columns: 1fr;
      gap: 25px;

      .views-row {
        margin-bottom: 0;

        .views-field-nothing {
          width: 100%;
          flex-direction: column;

          .field-content {

            .top {
              padding: 20px;

              .title {

                a {
                  font-size: 20px;
                  line-height: 22px;
                }
              }

              .author,
              .created {
                font-size: 16px;
                line-height: 22px;
              }
            }
          }
        }
      }
    }
  }
}
