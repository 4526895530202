// Import site utilities.

@import '../../global/utils/init';


.block-views-events-block {

  .block-title {
    margin-bottom: 45px;
  }

  .events-wrapper {
    text-align: center;

    .views-row {
      background: #cdbe86;
      border-bottom: 4px solid $white;
      margin: 0;
      padding: 35px 15px;

      &:last-child {
        border: 0;
      }

      .views-field-title {

        a {
          font-size: 30px;
          font-family: $font-franklin-demi;
          line-height: 36px;
          color: $white !important;
          text-decoration: none;
        }
      }

      .views-field-field-event-date {
        margin: 0;
      }

      .views-field-field-event-date,
      .views-field-field-public-location {
        font-size: 22px;
        font-family: $font-franklin-book;
        line-height: 30px;
        margin: 0;
      }
    }

    .view-footer {
      margin-top: 25px;

      a {
        color: $black !important;
        font-family: $font-franklin-book;
        font-size: 32px;
        line-height: 30px;
        text-decoration: underline;
      }
    }
  }
}

.front {

  .l-content {

    .block-views-events-block {
      background: $white !important;
      color: inherit !important;
    }
  }
}


//Paragraph type: Event Announcement

.paragraphs-item-event-announcement {
  background-color: $reno-sand;
  color: $color-white;
  padding: 54px 60px;

  .view-mode-teaser {
    margin: 0;
    padding: 0;

    .page-title {
      font-size: 30px;
      font-family: $font-franklin-demi;
      line-height: 31px;
      text-align: center;
      margin: 0 0 27px;
      background: none;
      margin-left: auto;
      height: auto;
      width: auto;
    }

    .content {
      padding-bottom: 30px;
      margin: 30px;
    }

    .field-name-body {
      margin: 0;

      p {
        line-height: 28px;
      }
    }

    .field-name-body,
    .field-name-field-event-date,
    .field-name-field-public-location {
      font-size: 22px;
      font-family: $font-franklin-book;
      line-height: 28px;
    }

    .field-name-field-public-location {
      margin-bottom: 25px;
    }

    .links {
      text-align: center;

      a {
        border: 1px solid $color-white;
        padding: 15px 30px;
        color: $color-white;
        font-size: 24px;
        font-family: $font-franklin-demi;
        line-height: 21px;
      }
    }
  }
}

//event details page
.node-event-new {

  .date_location_wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
    margin-bottom: 20px;

    .date_wrapper,
    .location_wrapper {
      border-top: 4px solid $black;
      border-right: 4px solid $black;
      font-size: 26px;
      font-family: $font-franklin-demi;
      line-height: 30px;
      padding: 15px 0;
    }

    .date-display-single,
    .location_wrapper {
      font-size: 26px;
      line-height: 30px;
    }

    .date-display-range {
      display: block;
    }
  }
}

//events & activities page design 

.view-events-activities {

  .view-header {
    margin-bottom: 70px;

    a {
      font-family: $font-franklin-demi;
      color: $pacific-blue;
    }

    .page_title_only {
      margin: 60px 0 50px;
      text-align: center;
    }

    img {
      width: 100%;
      margin-bottom: 90px;
    }
  }

  //event table design 

  .views-table {
    margin: 0 0 90px;

    th {
      padding: 0 15px 15px 0;

      &.views-field-field-public-location {
        padding-left: 15px;
      }
    }

    thead {
      
      th {
        background: transparent;
        border: none;
        font-weight: 400;
      }
    }

    tbody {

      td {
        border: 1px solid $dove-gray;
        padding: 15px 25px 15px 0;
        border-left: none;
        border-right: none;
        vertical-align: baseline;

        &:first-child {
          max-width: 325px;
        }

        &:last-child {
          max-width: 345px;
        }

        a {
          font-family: $font-franklin-demi;
          color: $pacific-blue;
        }

        .date-display-range {
          display: block;
        }

        &.views-field-field-public-location {
          background: $polar;
          padding-left: 15px;
        }
      }
    }
  }
}

@include lg {

  .block-views-events-block {

    .events-wrapper {

      .view-footer {

        a {
          font-size: 28px;
          line-height: 30px;
        }
      }
    }
  }
}

@include md {

  .node-event-new {

    .page-title {
      margin-bottom: 30px;
    }
    
    .date_location_wrapper {
      grid-template-columns: 100%;

      .date-display-single,
      .date_wrapper,
      .location_wrapper {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }

  .block-views-events-block {

    .block-title {
      margin-bottom: 20px;
    }

    .events-wrapper {

      .views-row {
        padding: 45px 20px;

        .views-field-title {
          margin-bottom: 10px;

          a {
            font-size: 20px;
            line-height: 22px;
          }
        }

        .views-field-field-event-date,
        .views-field-field-public-location {
          font-size: 16px;
          line-height: 22px;
        }
      }

      .view-footer {
        margin-top: 20px;

        p {
          margin-bottom: 0;
        }

        a {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }

  .paragraphs-item-event-announcement {
    padding: 36px 20px;

    .view-mode-teaser {

      .page-title {
        font-size: 22px;
        line-height: 24px;
        margin-bottom: 22px;
      }

      .field-name-body,
      .field-name-field-event-date,
      .field-name-field-public-location {
        font-size: 16px;
        line-height: 22px;
      }

      .field-name-body {

        p {
          font-size: 16px;
          line-height: 22px;
        }
      }

      .content {
        margin: 0;
      }

      .links {

        a {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }

  .view-events-activities {

    .view-header {
      margin-bottom: 30px;

      .page_title_only {
        font-size: 30px;
        line-height: 30px;
        margin: 30px 0;
      }

      img {
        margin-bottom: 20px;
      }
    }

    .views-table {
      margin: 0 0 25px;
    }
  }
}

