// Import site utilities.
@import '../../global/utils/init';

.slider_with_thumbnail {
  margin-bottom: 30px;
}

.sync2 {
  margin-top: 30px;

  .item {
    cursor: pointer;
    border-radius: 3px;
    text-align: center;

    img {
      height: 100px;
      min-width: 100%;
      object-fit: cover;
    }
  }
}

.owl-theme {

  .owl-nav {
    margin: 0;

    [class*='owl-'] {
      transition: all 0.3s ease;

      &.disabled:hover {
        background-color: $dove-gray;
      }
    }

    .owl-prev {
      margin: 0;
      left: -20px;
      position: absolute;
      top: 27px;

      span {
        font-size: 50px;
        line-height: 50px;
      }

      &:hover {
        background: no-repeat;
        color: $color-text-black;
      }
    }

    .owl-next {
      margin: 0;
      position: absolute;
      right: -20px;
      top: 27px;

      span {
        font-size: 50px;
        line-height: 50px;
      }

      &:hover {
        background: no-repeat;
        color: $color-text-black;
      }
    }
  }

  .owl-dots {
    margin-top: 30px;
  }
}

.slider_wrapper {
  margin-bottom: 30px;

  .container {
    padding: 0;
  }

  .owl-theme {

    .owl-nav {

      .owl-prev {
        background-color: $blue-chill;
        border-radius: 5%;
        display: flex;
        justify-content: center;
        align-items: center;
        left: 20px;
        top: 302px;
        height: 40px;
        width: 30px;

        span {
          border-left: 2px solid $color-white;
          border-top: 2px solid $color-white;
          display: block;
          height: 15px;
          opacity: 1;
          text-indent: -999999px;
          transform: rotate(-45deg);
          transition: 0.5s;
          width: 15px;
          margin-right: -7px;

          &:hover {
            opacity: 1;
          }
        }
      }

      .owl-next {
        border-radius: 5%;
        background-color: $blue-chill;
        display: flex;
        justify-content: center;
        align-items: center;
        right: 20px;
        height: 40px;
        width: 30px;
        top: 302px;

        span {
          border-right: 2px solid $color-white;
          border-top: 2px solid $color-white;
          display: block;
          height: 15px;
          opacity: 1;
          text-indent: -999999px;
          transform: rotate(45deg);
          transition: 0.5s;
          width: 15px;
          margin-left: -7px;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  .slider_caption {
    border-bottom: 1px solid $dove-gray;
    color: $mine-shaft;
    padding: 20px 80px;

    p {
      font-size: 18px;
      line-height: 24px;
      font-family: $font-franklin-oblique;
      margin-bottom: 15px;

      &:last-child {
        margin: 0;
      }
    }
  }
}

//arrows on first carousel
.sync1.owl-theme {
  position: relative;

  .owl-next,
  .owl-prev {
    width: 22px;
    height: 40px;
    margin-top: -20px;
    position: absolute;
    top: 50%;
  }

  .owl-prev {
    left: 10px;
  }

  .owl-next {
    right: 10px;
  }
}

@include md {
  //Responsive design for slider with thumbnail
  .sync2 {

    .item {

      img {
        height: auto;
        min-width: 100%;
        object-fit: contain;
      }
    }
  }

  .owl-theme {

    .owl-nav {

      &[class*='owl-']{

        .disabled {

          &:hover {
            background-color: unset;
          }
        }
      }

      .owl-prev {
        left: -15px;
      }

      .owl-next {
        right: -15px;
      }

      .owl-prev,
      .owl-next {
        top: 25%;

        span {
          line-height: 0;
        }
      }
    }
  }

  //Responsive design for slider without thumbnail

  .slider_wrapper {

    > .container {
      padding: 0;
    }

    .owl-theme {

      .owl-nav {

        .owl-prev,
        .owl-next {
          top: 96px;
          height: 34px;

          span {
            font-size: 100px;
            height: 10px;
            line-height: 0px;
            width: 10px;
          }
        }
      }
    }

    .slider_caption {
      padding: 20px 0;

      p {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .slider_with_thumbnail {

    > .container {
      padding: 0;
    }
  }
}
