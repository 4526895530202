// Import site utilities.
@import '../../global/utils/init';

.addtional-space {
  margin-bottom: 100px !important;
}

.paragraphs-items-field-component {

  h3 {
    font-size: 34px;
    line-height: 38px;
  }
}

.paragraphs-item {
  margin-bottom: 30px;

  .field-name-field-image {
    padding: 0;
  }

  .field-item {

    &:last-child {

      .paragraphs-item {
        margin-bottom: 0;
      }
    }
  }
}

@include lg {

  .paragraphs-item {
    margin-bottom: 30px;
  }

  .addtional-space {
    margin-bottom: 30px !important;
  }
}


//text paragraph design

.paragraphs-item-text-block {

  .field-name-field-title,
  .field-name-field-description {

    .field-item {
      font-size: 22px;
      line-height: 30px;

      a {
        color: $pacific-blue;
      }

      p {

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

//paragraph image with caption

.paragraphs-item-image-with-caption {

  .field-type-image {
    line-height: 0;
  }

  .field-name-field-caption {
    padding-top: 10px;

    .field-item {
      color: $mine-shaft;
      font-size: 20px;
      line-height: 28px;

      p {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
}

//paragraph embedded content, accordion - space after h3

.paragraphs-item-embedded-content {

  h3 {
    margin-bottom: 35px;
  }
}

.paragraphs-item-accordion {

  h3 {
    margin-bottom: 18px;
  }
}

//paragraph call to action design

.paragraphs-item-call-to-action {

  .view-mode-teaser {
    margin: 0;
  }

  .node-call-to-action {
    padding: 45px 60px;

    > h3 {
      font-size: 30px;
      line-height: 31px;
      text-align: center;
      margin-bottom: 10px;
    }

    img {
      width: 100%;
    }

    .field-name-body {
      margin-bottom: 0;

      p {
        line-height: 28px;
      }

      a {
        color: inherit;
        text-decoration: underline;
      }
    }

    .field-name-field-link {
      text-align: center;
      margin-top: 40px;

      a {
        border: 1px solid;
        border-color: inherit;
        color: inherit;
        font-size: 24px;
        font-family: $font-franklin-demi;
        line-height: 24px;
        padding: 14px 48px;
      }
    }
  }
}

//paragraphs-item-custom-block

.paragraphs-item-custom-block {

  .block {
    margin-bottom: 0 !important;

    p {

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@include md {

  .paragraphs-items-field-component {

    h3 {
      font-size: 26px;
      line-height: 28px;
    }
  }

  .paragraphs-item {

    .field-name-field-image {
      padding: 0;
    }
  }

  .paragraphs-item-text-block {

    .field-name-field-title,
    .field-name-field-description {

      .field-item {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }

  //paragraph call to action design

  .paragraphs-item-call-to-action {

    .node-call-to-action {
      padding: 36px 20px;

      h3 {
        font-size: 22px;
        line-height: 31px;
      }

      .field-name-body {

        p {
          font-size: 16px;
          line-height: 22px;
        }
      }

      .field-name-field-link {
        margin-top: 40px;

        a {
          border: 1px solid;
          font-size: 18px;
          line-height: 21px;
          padding: 12px 35px;
        }
      }
    }
  }

  .paragraphs-item-image-with-caption {

    .field-name-field-caption {

      .field-item {
        font-size: 14px;
        line-height: 20px;

        p {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}
