// Import site utilities.
@import '../../global/utils/init';

.l-footer {
  background: url('../images/WaterArt.png') no-repeat;
  background-size: cover;
  border: none !important;
  padding: 67px 0;
  text-align: center;

  p {
    font-size: 14px;
    font-weight: bold;
  }

  .footer-contact-us {

    .block-content {

      p {
        margin-bottom: 0;
      }

      a {
        border: 2px solid $white;
        color: $white;
        display: inline-block;
        font-size: 22px;
        font-family: $font-franklin-medium;
        font-weight: 100;
        line-height: 42px;
        padding: 0 65px;
      }
    }
  }

  .block-on-the-web-social-links {
    margin: 45px 0;

    .block-content {

      a {
        color: $white;
        margin-right: 33px;

        &:last-child {
          margin: 0;
        }

        i {
          font-size: 46px;
          opacity: 0.6;
        }

        span {

          &.ext {
            display: none;
          }
        }
      }
    }
  }

  .footer-address,
  .footer-copyright {
    font-family: $font-franklin-medium;
    font-size: 18px;
    font-weight: 100;
    line-height: 28px;
  }

  .footer-address {
    margin-bottom: 30px;

    a {
      color: $white;
      text-decoration: underline;
    }
  }

  .footer-copyright {
    margin: 0;
  }

  @include md {
    padding: 50px 0;

    .l-footer-inner {
      display: flex;
      flex-direction: column;

      > .block {

        &:first-child {
          order: 1;
        }

        &:nth-child(2) {
          order: 0;
        }

        &:nth-child(3) {
          order: 2;
        }
      }

      .footer-contact-us {
        margin: 0 0 30px;

        .block-content {

          a {
            padding: 5px 28px;
          }
        }
      }

      .block-on-the-web-social-links {
        margin: 0 0 30px;

        a {
          margin-right: 20px;

          i {
            font-size: 30px;
          }
        }
      }
    }

    .footer-address,
    .footer-copyright {
      font-size: 16px;
      line-height: 22px;
    }
  }
}
