@import '../../global/utils/init';

.view-person-list {
  margin: 0 auto;

  .view-header {
    padding: 0 0 40px;

    p {
      font-size: 22px;
      font-family: $font-franklin-book;
      line-height: 30px;
    }
  }

  .view-content {

    .views-row {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid $dove-gray;
      margin-bottom: 0;
      padding: 35px 0;

      .views-field {
        margin: 0;
      }

      .info {

        a {
          font-size: 30px;
          font-family: $font-franklin-demi;
          line-height: 30px;
          text-decoration: none;
        }

        .role {
          font-size: 22px;
          font-family: $font-franklin-book;
          line-height: 30px;
          padding-right: 15px;
        }
      }

      &:last-child {
        border-bottom: 1px solid;
      }

      .views-field-field-profile-picture {
        margin: 0;

        .field-content {
          line-height: 0;
          height: 100%;
          
          img {
            height: 100%;
          }
        }
      }
    }
  }
}

@include md {

  .view-person-list {

    .view-header {
      padding: 0 0 25px;
      
      p {
        font-size: 18px;
        line-height: 24px;
      }
    }

    .view-content {

      .views-row {
        flex-direction: column;
        padding: 30px 0;

        .field-content {
          display: flex;

          .info {
            padding: 0 0 20px;

            a {
              font-size: 20px;
              line-height: 22px;
            }
          }

          .role {
            font-size: 16px;
            line-height: 22px;
          }
        }

        .views-field-field-profile-picture {
          display: flex;
          justify-content: center;

          img {
            max-width: 210px;
          }
        }
      }
    }
  }
}
