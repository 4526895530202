// Import site utilities.
@import '../../global/utils/init';

.menu {

  li {

    a {
      color: $denim;
      font-size: 20px;
      font-family: $font-franklin-demi;
      line-height: 20px;
      padding: 0 10px !important;
      text-transform: uppercase;
    }
  }

  > .has-children {
    position: relative;

    > ul {
      background: transparent;
      display: none;
      left: 0;
      padding: 0;
      position: absolute;
      top: 100%;
      width: 300px;
      z-index: 10;
      padding-top: 23px;

      li {
        background: $white;
        padding: 5px 0;

        &:last-child {
          padding-bottom: 15px;
        }

        a {
          color: $black;
          font-family: $font-franklin-book;
          font-size: 18px;
          line-height: 28px;
          padding: 0 25px !important;
          text-transform: initial;

          &.active {
            color: $tradewind;
          }
        }
      }
    }

    &:hover {

      > ul {
        display: block;
      }
    }
  }
}

//header top menu
.block-menu-menu-header-top-menu {

  .menu {

    li {

      a {
        color: $blue-chill;
        font-size: 30px;
        font-family: $font-franklin-demi;
        line-height: 30px;
        padding: 0 !important;
        text-transform: capitalize;

        &::after {
          content: '|';
          color: $blue-chill;
          font-size: 25px;
          padding: 0 15px;
        }
      }

      &:last-child {
        border: 0;

        a {

          &::after {
            content: '';
            padding: 0;
          }
        }
      }
    }
  }
}

@include lg {

  .menu-toggle-state {

    &:not(:checked) {

      ~ .menu {
        display: none;
      }
    }
  }

  .l-header {

    .block-system-main-menu {

      .menu-toggle-button {
        height: 30px;
        margin: 0;
        padding: 0;
        text-indent: 0;
        width: 30px;
        top: 5px;

        .menu-toggle-button-text {
          display: none;
        }
      }

      .menu-toggles-processed {
        background: $color-white;
        left: 0;
        min-width: 300px;
        padding-left: 10px;
        position: absolute;
        top: calc(100% + 20px);
        z-index: 10;

        > li {
          width: 100%;

          &:last-child {
            padding-bottom: 10px;
          }

          > a {
            font-size: 18px;
            line-height: 28px;
          }
        }
      }
    }
  }

  .menu {

    > .has-children {

      > ul {
        width: 200px;
        padding: 0 0 0 15px;
        position: unset;

        li {

          a {
            font-size: 16px;
            line-height: 22px;
            color: $midnight-blue;
          }
        }
      }
    }
  }

  .block-menu-menu-header-top-menu {
    margin-bottom: 15px !important;
    text-align: center;

    .menu {
      display: inline-block;

      li {

        a {
          font-size: 24px;
          line-height: 28px;

          &::after {
            font-size: 18px;
          }
        }
      }
    }
  }
}

@include md {

  .l-header {

    .block-system-main-menu {

      .menu-toggles-processed {
        min-width: 200px;

        li {

          a {
            font-size: 14px;
            line-height: 23px;
          }
        }
      }
    }
  }

  .block-menu-menu-header-top-menu {

    .menu {

      li {

        a {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
  }

  .menu {

    > .has-children {

      > ul {

        li {
          padding: 3px 0;
        }
      }
    }
  }
}

