// Import site utilities.
@import '../../global/utils/init';

.node-type-frameless {

  .layout {

    .container {
      max-width: unset;
      padding: 0;
      width: 100%;
    }

    .l-messages,
    .messages,
    .page-title,
    footer {
      display: none;
    }

    iframe {
      border: 0;
    }

    .field-name-body {
      margin: 0;

      .field-item {
        height: 100vh;
      }
    }
  }

  &.logged-in {

    .layout {

      .field-name-body {

        .field-item {
          height: calc(100vh - 83px);
        }
      }
    }
  }
}
