// Import site utilities.

@import '../../global/utils/init';

.front {

  .block-views-updates-block-1 {
    padding: 0 !important;
  }

  .strech_river {
    aspect-ratio: 16/8;
    background-size: cover;
    background-color: $midnight-blue !important;
    cursor: pointer;

    .block-content {
      max-width: 350px;
      text-align: left;
      margin-left: auto;
      margin-right: 180px;
      margin-top: 120px;

      p {

        a {
          font-family: $font-franklin-demi;
          font-size: 30px;
          line-height: 36px;
          text-decoration: none;
          color: $white !important;
          pointer-events: none;
        }
      }
    }
  }

  .layout-front {

    .block-hero {
      margin-bottom: 100px;
    }
  }
}

@include xl {

  .front {

    .strech_river {

      .block-content {
        max-width: 280px;
        margin-left: auto;
        margin-right: 0;
        margin-top: 0;

        p {
          
          a {
            font-size: 24px;
            line-height: 26px;
          }
        }
      }
    }
  }
}

@include lg {

  .front {

    .layout-front {

      .block-hero {
        margin-bottom: 50px !important;
      }
    }
  }
}

@include md {

  .front {

    .block-views-updates-block-1 {
      margin-bottom: 25px;
    }

    .layout-front {

      .block-hero {
        margin-bottom: 30px !important;
      }
    }

    .strech_river {
      aspect-ratio: 1;
      background-size: contain;
      background-position: bottom;

      .block-content {
        max-width: 280px;
        margin: 15px auto 0;

        p {
          
          a {
            font-size: 24px;
            line-height: 26px;
          }
        }
      }
    }
  }
}

@media (min-width:992px) and (max-width:1229px) {
  
  .block-sign-up {

    p {

      a {
        margin-top: 30px;
      }
    }
  }
}
