// Import site utilities.
@import '../../global/utils/init';

.block-hero-image {
  padding: 0 !important;

  .hero-inner {
    padding: clamp(10px, 5vw, 70px) 0;
  }

  .container {
    position: inherit;
  }

  .block-title {
    color: $white;
    font-size: 34px;
    text-shadow: unset;
  }
}

.front-featured-hero {
  min-height: unset !important;

  .hero-inner {
    backdrop-filter: blur(2px);
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    padding: 150px 0;

    .block-title {
      font-size: 60px;
      font-family: $font-franklin-demi;
      line-height: 66px;
      text-transform: inherit;
    }
  }
}

.featured-hero-btn {
  display: inline-block;
  margin: 50px 0 0;

  a {
    align-items: center;
    background: url('../images/join2.png') no-repeat;
    background-size: cover;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.7) !important;
    display: flex;
    float: left;
    font-size: 36px;
    font-weight: bold;
    font-family: $font-franklin-demi;
    height: 150px;
    justify-content: center;
    line-height: 36px;
    margin: 0 30px 30px 0;
    padding: 15px;
    text-decoration: none;
    text-transform: uppercase;
    width: 300px;

    &:nth-child(2) {
      background: url('../images/advocate2.png') no-repeat;
      background-size: cover;
    }

    &:last-child {
      background: url('../images/volunteer2.png') no-repeat;
      background-size: cover;
      margin-right: 0;
    }
  }
}

.stretch-of-the-river-title {
  background: $white !important;
  min-height: unset !important;
  padding-bottom: 0 !important;
  text-align: center;
  text-transform: uppercase;
}

.stretch-of-the-river {
  background-position: bottom;

  .container {

    .block-content {
      color: $white;
      font-weight: bold;
      float: right;
      max-width: 340px;
      text-align: left;
    }
  }
}


//frontpage Donate hero block
.donate_hero {

  .hero-inner {
    backdrop-filter: blur(5px);
    background: rgba(0, 0, 0, 0.3);
    padding: 110px 0 125px;
  }

  .block-content {
    color: $white;
    margin: 0 auto;
    max-width: 800px;

    p {
      font-size: 24px;
      font-family: $font-franklin-medium;
      font-weight: 100;
      line-height: 30px;
      margin-bottom: 35px;
    }

    a {
      border: 2px solid $white;
      color: $white !important;
      display: inline-block;
      font-size: 24px;
      font-weight: $font-franklin-medium;
      font-weight: 100;
      margin-top: 35px;
      padding: 10px 35px;
      text-decoration: none;
      text-transform: uppercase;
    }
  }

  .block-title {
    font-size: 60px;
    font-family: $font-franklin-demi;
    line-height: 60px;
    margin: 0 auto 35px;
    max-width: 800px;
    text-transform: inherit;
  }
}

// Hero page title
.hero_content_wrapper {
  align-items: center;
  display: flex;
  margin-bottom: 100px;
  margin-left: calc(-50vw + 50%);
  min-height: 600px;
  padding: 100px 0;
  width: 100vw;

  h1 {
    color: $color-white;
    font-size: 60px;
    font-family: $font-franklin-demi;
    line-height: 60px;
    text-align: center;

    &.page-title {
      margin: 0;
    }
  }

  .field-name-field-hero-description {
    color: $color-white;
    text-align: center;
    margin-top: 30px;

    p {
      margin: 0 0 30px;
      font-size: 24px;
      letter-spacing: 0;
      line-height: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      color: $cornflower;
      text-decoration: none;
    }
  }

  .field-name-field-link {
    margin-top: 30px;
    text-align: center;

    a {
      background: transparent;
      border: 2px solid $color-white;
      color: $color-white;
      display: inline-block;
      font-size: 24px;
      line-height: 30px;
      padding: 10px 30px;
      text-decoration: none;
      text-transform: uppercase;
    }
  }
}

// Existing content custom Hero block
.content_hero_wrapper {

  .content_hero_inner {
    color: $color-white;
    display: flex;

    h2 {
      font-size: 60px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 60px;
    }

    .field-name-body {
      font-size: 24px;
      letter-spacing: 0;
      line-height: 30px;
      margin: 0;

      a {
        color: $cornflower;
        text-decoration: none;
      }

      p {
        font-size: 24px;
        letter-spacing: 0;
        line-height: 30px;
      }
    }

    .field-name-field-link {
      margin-top: 30px;
      text-align: center;

      a {
        background: transparent;
        border: 2px solid $color-white;
        color: $color-white;
        display: inline-block;
        font-size: 24px;
        line-height: 30px;
        padding: 10px 30px;
        text-decoration: none;
        text-transform: uppercase;
      }
    }

    .field-name-field-hero-image {
      width: 100%;

      img {
        display: block;
        height: 100%;
        min-width: calc(100% + 1px);
        object-fit: cover;
      }
    }

    .content_hero_left,
    .content_hero_right {
      align-items: center;
      display: flex;
      flex-basis: 50%;
      flex-direction: column;
      justify-content: center;
      padding: 100px;
      text-align: center;

      &.image_wrapper {
        padding: 0;

        div {
          height: 100%;
        }
      }
    }

    &.bg_image {
      padding: 130px 0;
      text-align: center;
    }
  }
}

// Paragraph existing hero content
.paragraphs-item-existing-content {
  margin-left: calc(-50vw + 50%);
  width: 100vw;
}

@include lg {

  .hero_content_wrapper {
    min-height: 400px;
    margin: 0 0 50px calc(-50vw + 50%);

    h1 {
      font-size: 40px;
      line-height: 48px;
    }
  }

  .content_hero_wrapper {

    .content_hero_inner {

      .content_hero_right {
        padding: 40px;
      }

      h2 {
        font-size: 40px;
        line-height: 48px;
      }
    }

    .field-name-body {

      h3 {
        font-size: 28px;
        line-height: 30px;
      }
    }
  }

  .block-hero-image {

    .block-title,
    .block-content {
      display: block;
    }
  }

  .front-featured-hero {
  
    .hero-inner {
      padding: 100px 0;

      .block-title {
        font-size: 40px;
        line-height: 48px;
      }
    }
  }

  .featured-hero-btn {

    a {
      font-size: 28px;
      line-height: 32px;
      height: 100px;
      width: 200px;
      margin: 0 20px 20px 0;
    }
  }

  .donate_hero {

    .hero-inner {
      padding: 100px 0;
    }

    .block-title {
      font-size: 40px;
      line-height: 48px;
    }

    .block-content {

      p {
        font-size: 22px;
        line-height: 30px;
      }
    }
  }
}

@include md {

  .hero_content_wrapper {
    min-height: unset;
    margin-bottom: 30px;

    h1 {
      font-size: 30px;
      line-height: 30px;
    }

    .field-name-field-hero-description {

      p {
        font-size: 18px;
        line-height: 24px;
      }
    }

    .field-name-field-link {

      a {
        font-size: 20px;
        padding: 12px 17px;
      }
    }
  }

  .front-featured-hero {

    .hero-inner {
      padding: 30px 0;

      .block-title {
        font-size: 18px;
        line-height: 24px;
        margin: 0;
        padding: 0 30px;
      }
    }
  }

  .featured-hero-btn {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 0 25px;
    padding: 0 15px;

    a {
      font-size: 24px;
      line-height: 49px;
      margin: 0 0 20px;
      height: 90px;
      width: 180px;
      padding: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .donate_hero {

    .hero-inner {
      padding: 50px 0;

      .container {
        padding-left: 40px;
        padding-right: 40px;
      }
    }

    .block-title {
      font-size: 30px;
      line-height: 30px;
      margin-bottom: 20px;
    }

    .block-content {

      p {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        font-size: 20px;
        line-height: 30px;
        margin-top: 20px;
        padding: 12px 16px;
      }
    }
  }

  .content_hero_wrapper {

    .content_hero_inner {

      &.left_image {
        flex-direction: column;
      }

      &.right_image {
        flex-direction: column-reverse;
      }

      .content_hero_left {

        &.image_wrapper {
          order: 2;
        }
      }

      .content_hero_full,
      .content_hero_left,
      .content_hero_right {
        padding: 50px 40px;

        h2 {
          font-size: 30px;
          line-height: 32px;
          margin-bottom: 20px;
        }

        .field-name-body {
          font-size: 18px;
          line-height: 24px;
        }

        .field-name-field-link {
          
          a {
            font-size: 20px;
            line-height: 30px;
            padding: 10px 17px;
          }
        }
      }

      .content_hero_full {
        padding: 0;
      }
    }
  }
}
