@import '../../global/utils/init';

.paragraphs-item-quotes {

  .quote-link {
    position: relative;
    z-index: 10;

    &:hover {
      text-decoration: none;
    }
  }

  .content {
    display: flex;
    gap: 25px;
    padding: 60px 0;
    border-top: 2px solid $steel-blue;
    border-bottom: 2px solid $steel-blue;

    .field-name-field-quote-image {
      flex-basis: 270px;

      .field-items {
        line-height: 0;
      }
    }

    .field-name-field-body {
      color: $lochmara;
      font-family: $font-franklin-oblique;
      flex-basis: calc(100% - 295px);
      display: grid;
      align-items: center;
      margin: 0 auto;

      p {
        font-size: 22px;
        line-height: 34px;
        margin: 0;

        em {
          font-size: 26px;
          line-height: 34px;
        }
      }
    }

    .field-name-field-add-extra-space,
    .field-name-field-hide-bottom-line,
    .field-name-field-add-link,
    .field-name-field-linked-content {
      display: none;
    }
  }

  &.hide_bottom_line {
    margin-bottom: 0 !important;

    .content {
      border-bottom: 0;
    }
  }
}

@include md {

  .paragraphs-item-quotes {

    .content {
      flex-direction: column;
      gap: 15px;
      padding: 30px 0;

      .field-name-field-quote-image {
        text-align: center;
        order: 2;
      }

      .field-name-field-body {
        order: 1;
        flex-basis: unset;

        p {
          font-size: 16px;
          line-height: 26px;
          margin: 0;

          em {
            font-size: 20px;
            line-height: 26px;
          }
        }
      }
    }
  }
}
