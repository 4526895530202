// Import site utilities.
@import '../../global/utils/init';

.block-menu-menu-special-menu {

  ~ .container {

    > article {

      .hero_content_wrapper,
      .page-title {
        display: none;
      }
    }
  }

  h2 {

    &.block-title {
      background: $pacific-blue;
      color: $color-white;
      margin: 0 0 100px calc(-50vw + 50%);
      padding: 125px 30px;
      width: 100vw;
      text-transform: capitalize;
    }
  }

  .menu {
    display: flex;
    margin: 0;
    padding: 0;

    li {
      align-items: center;
      border: 1px solid $silver-sand;
      border-bottom: 1px solid $color-text-black;
      display: flex;
      flex-basis: 20%;
      padding: 0;
      text-align: center;

      &::marker {
        content: '';
      }

      a {
        color: $gray;
        display: inline-block;
        font-family: $font-franklin-demi;
        font-size: 24px;
        line-height: 24px;
        padding: 30px 29px !important;
        text-decoration: none;
        text-transform: initial;
      }

      &.active-trail {
        border-bottom: 0;
        border-color: $color-text-black;

        a {
          color: $color-text-black;
        }
      }
    }
  }
}

@include md {

  .block-menu-menu-special-menu {

    h2 {

      &.block-title {
        padding: 100px 0;
        margin-bottom: 30px;
      }
    }

    .menu {
      flex-direction: column;

      li {
        border-bottom: 1px solid $silver-sand;
        border-top: 0;

        &.first {
          border-top: 1px solid $silver-sand;
        }

        &.active-trail {
          border-color: $silver-sand;
          border-bottom: 1px solid $silver-sand;
        }

        a {
          font-size: 20px;
          padding: 20px !important;
          width: 100%;
          display: block;
        }
      }
    }
  }
}
