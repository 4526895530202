// Import site utilities.

@import '../../global/utils/init';

.ui-accordion {

  .ui-accordion-header {
    background: none;
    background-color: $white !important;
    border: none;
    border-bottom: 1px solid $dove-gray;
    border-right: 1px solid $dove-gray;
    border-radius: 0;
    padding-left: 0 !important;

    .ui-icon {
      display: none;
    }

    a {
      color: $black !important;
      font-family: $font-franklin-book;
      padding-right: 25px;
      display: block;
      position: relative;

      &::after {
        content: '+';
        color: $gray;
        font-size: 25px;
        font-family: $font-franklin-demi;
        position: absolute;
        right: 20px;
        top: calc(50% - 15px);
      }
    }
  }

  .ui-accordion-header-active {

    a {

      &::after {
        content: '-';
        font-size: 50px;
        font-family: $font-franklin-book;
      }
    }
  }

  .ui-accordion-content {
    padding: 30px !important;
    border: none;
    background: none;
    background-color: $polar;

    a {
      color: $pacific-blue;
    }
  }
}

@include md {

  .ui-accordion {

    .ui-accordion-header {
      
      a {

        &::after {
          right: 10px;
        }
      }
    }
  }
}
