// Import site utilities.
@import '../../global/utils/init';

/**
 * @file
 * Component Layout CSS and some Page layout overrides
 */

.layout {

  .l-messages {
    margin: 0;
    padding: 0;

    .messages {
      margin: 30px 0;
    }
  }
}


.l-wrapper .block--inner-wrapper.container {
  margin-left: auto;
  margin-right: auto;
}
