// Import site utilities.
@import '../../global/utils/init';

#cboxOverlay {
  background: $blue;
  position: fixed;
  top: initial;
  bottom: 0;
  width: 100%;
  height: 330px;
  border: none;
  z-index: 100;
  opacity: 1 !important;
}

#cboxTopLeft,
#cboxTopCenter,
#cboxTopRight {
  height: 0 !important;
}

#colorbox {
  position: fixed !important;
  bottom: 0;
  top: initial !important;
  z-index: 100;
  outline: none;
}

#cboxWrapper {
  border-radius: 0;
  width: 100% !important;
}

#cboxMiddleLeft {
  width: 0 !important;
}

#cboxLoadedContent {
  width: 100% !important;
  margin: 0;
  height: auto !important;

  iframe {
    aspect-ratio: 16/9;
    height: auto;
    width: 100%;
  }
}

#cboxContent {
  position: unset;
  height: 100% !important;
  width: 100% !important;
}

#cboxClose {
  background: none;
  position: absolute;
  bottom: initial;
  color: $white;
  top: 0;
  right: 0;
  width: auto;
  padding: 15px 20px;
  line-height: 0px;
  text-indent: 0;
  font-size: 18px;
  font-weight: bold;
  background-color: $curious-blue;
  border: 1px solid #2284a1;
}

.pop-up-wrapper {
  background-color: $white;
  height: 350px;
  display: flex;

  .pop-up-left {
    display: flex;
    flex-basis: 50%;

    img {
      flex-basis: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .pop-up-right {
    align-self: center;
    flex-basis: 50%;
    padding: 0 20px;

    h2 {
      font-size: 28px;
      margin-bottom: 25px;
      line-height: 32px;
    }

    p {
      margin-bottom: 15px;
    }

    a {
      color: $curious-blue;
    }
  }
}

.donate-now {
  text-align: center;
  margin-top: 20px;

  a {
    color: $curious-blue;
    display: inline-block;
    border: 3px solid $curious-blue;
    padding: 3px 10px;
    text-align: center;
  }
}

@include md {

  #cboxOverlay {
    top: 0;
    bottom: initial;
    height: 100%;
  }

  #cboxWrapper {
    height: 570px !important;
    left: initial;
    right: initial;
  }

  #colorbox {
    height: 570px !important;
    width: calc(100% - 60px) !important;
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%);
  }

  #cboxClose {
    font-size: 15px;
    padding: 15px;
  }

  #cboxLoadedContent {
    height: 100% !important;

    iframe {
      height: 100%;
    }
  }

  #cboxContent{
    height: 570px !important;
  }

  .pop-up-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;

    .pop-up-left {
      width: 100%;
    }

    .pop-up-right {
      display: block;
      padding: 15px;

      h2 {
        font-size: 18px;
        line-height: 21px;
        margin-bottom: 15px;
      }

      p {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
