@import '../../global/utils/init';

.paragraphs-item-multi-links {
  margin-top: 15px;

  .content {
    text-align: center;

    .field-name-field-title {
      color: $lochmara;
      border-top: 2px solid $lochmara;
      font-size: 36px;
      font-family: $font-franklin-demi;
      line-height: 36px;
      padding: 35px 0;
    }

    .field-name-field-link {
      font-size: 22px;
      line-height: 31px;
      font-family: $font-franklin-medium;

      .field-item {
        background-color: $lochmara;
        margin-bottom: 2px;
        padding: 15px 20px;

        a {
          color: $color-white;
          text-decoration: none;
        }
      }
    }

    .field-name-field-link-footer {
      padding-top: 35px;
      font-size: 21px;
      font-family: $font-franklin-demi;
      line-height: 21px;

      a {
        text-decoration: none;
        color: $lochmara;
      }
    }
  }
}

.front {

  .paragraphs-item-multi-links {

    .field-name-field-link {
      
      a {
        color: $white !important;
      }
    }
  }

  .node-stock-primer {
    
    &.container {
      max-width: 990px !important;
    }
  }
}

.block {

  .paragraphs-item-multi-links {
    margin: 0;
  }
}

.paragraphs-item-stock-primer-block {

  .node-stock-primer {
    padding: 0 !important;
  }
}

@include md {
  
  .paragraphs-item-multi-links {

    .content {

      .field-name-field-title {
        font-size: 20px;
        line-height: 21px;
      }

      .field-name-field-link {
        font-size: 16px;
        line-height: 18px;
      }

      .field-name-field-link-footer {
        font-size: 14px;
        line-height: 21px;
        padding-bottom: 30px;
        border-bottom: 2px solid $lochmara;
      }
    }
  }
}

