// Import site utilities.
@import '../../global/utils/init';

.card-list-wrapper {

  //draggable view save button for admin

  .form-submit {
    background: $blue;
    color: $color-white;
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;

    &:hover {
      border: 0;
      background-color: $blue;
      color: $color-white;
    }
  }

  .tabledrag-processed {
    width: 100%;
  }

  .view-header {

    h2 {
      color: $black;
      font-weight: bold;
      margin: 0;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .view-all-btn {
    margin-top: 30px;
    text-align: center;

    a {
      color: $color-text-black !important;
      font-family: $font-franklin-book;
      font-size: 32px;
      line-height: 30px;
      text-decoration: underline;
    }
  }

  // table {
  //   margin: 0;
  // }

  .General {
    padding-top: 10px;
  }

  .views-row {
    margin-bottom: 30px;

    &.last {
      margin-bottom: 0;
    }

    .field-content {
      padding: 0;
      display: grid;
      grid-template-columns: 50% 50%;
      grid-auto-rows: 450px;
    }

    .card-list-left {
      order: 1;

      a {
        display: grid;
      }

      img {
        min-width: 100%;
        object-fit: cover;
      }
    }

    .card-list-right {
      background: $blue;
      display: grid;
      order: 2;
      padding: 25px 60px 0;
      // min-height: 450px;

      .update-type {
        color: $cornflower;
        font-size: 21px;
        font-family: $font-franklin-demi;
        line-height: 28px;
        letter-spacing: 4.2px;
        border-bottom: 1px solid $black;
        padding: 5px 0 14px;
        text-align: center;
        text-transform: uppercase;
      }

      .author-created {
        color: $midnight-blue;
        font-family: $font-franklin-medium;
        font-size: 18px;
        line-height: 37px;
      }

      .title {
        margin-bottom: 24px;
        
        a {
          color: $white !important;
          font-size: 30px;
          line-height: 32px;
          font-family: $font-franklin-demi;
          text-decoration: none;
        }
      }

      .body {
        font-size: 18px;
        font-family: $font-franklin-medium;
        line-height: 28px;
        color: $white;

        .read-more {
          color: $cornflower !important;
          font-size: 18px;
          font-family: $font-franklin-demi;
          line-height: 0;
          margin-left: 10px;
        }
      }
    }

    &.even {

      .card-list-left {
        order: 2;
      }

      .card-list-right {
        order: 1;
      }
    }
  }

  @include xl {

    .views-row {

      .card-list-right {
        padding: 40px;
      }

      .field-content {
        grid-auto-rows: unset;

        .card-list-left {
          display: flex;
        }
      }
    }
  }

  @include lg {

    .views-row {

      .card-list-right {

        .title {

          a {
            font-size: 28px;
          }
        }


        .author-created {
          padding: 10px 0;
          line-height: 22px;
        }

        .update-type {
          font-size: 18px;
          line-height: 28px;
          letter-spacing: 3px;
        }
      }
    }
  }

  @include md {

    .General {
      padding-top: 5px;
    }

    .views-row {
      margin-bottom: 20px;

      .field-content {
        grid-template-columns: 1fr;
        grid-auto-rows: unset;

        .card-list-left {
          order: 1;
        }

        .card-list-right {
          min-height: unset;
          order: 0;
          padding: 20px;

          .update-type {
            font-size: 15px;
            letter-spacing: 3px;
            line-height: 31px;
            margin: 0 0 15px;
            padding: 0 0 15px;
          }

          .author-created {
            font-size: 16px;
            line-height: 20px;
            margin: 0 0 16px;
          }

          .title {
            margin: 0 0 15px;

            a {
              font-size: 20px;
              line-height: 22px;
            }
          }

          .body {
            font-size: 16px;
            line-height: 22px;

            .read-more {
              font-size: 16px;
              line-height: 0;
            }
          }
        }
      }
    }

    .view-all-btn {
      margin-top: 15px;
  
      a {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}
