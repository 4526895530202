// Colors.
//
// All Sass color variables prefixed with $color-.
// This makes it easy to figure out what a variable
// is for later.
//
// If you need help naming colors check out:
// http://chir.ag/projects/name-that-color/

// Standard.
$color-white: #ffffff;
$color-black: #020202; // for black hover
$color-bg-black: #000001; // for header and footer and background
$color-link-black: #000002; // for links
$color-text-black: #000000; // for texts
$black: $color-text-black;
$white: $color-white;
$gray: #939090;
$grey: $gray;

// Primary.
$color-mandy: #ec5f67;
$color-tan-hide: #f99157;
$color-saffron-mango: #fac863;
$color-de-york: #99c794;
$color-tradewind: #5fb3b3;
$color-danube: #69c;

// Grays.
$color-tundora: #464646;
$color-gray-dk: #343d46;
$color-gray-mid: #4f5b66;
$color-gray-lt: #65737e;
$color-gray-xlt: #a7adba;

//Fmr Colors
$acapulco: #77b0a3;
$blue-chill: #0ea39e;
$lochmara: #0079c1;
$cornflower: #9ad9e9;
$midnight-blue: #003767;
$buttered-rum: #9b7d0d;
$pacific-blue: #009ac7;
$reno-sand: #9e6614;
$dove-gray: #707070;
$periwinkle-gray: #b3cae2;
$denim: #1672b5;
$tradewind: #4eb3a3;
$steel-blue: #4178bf;
$silver-sand: #c3c3c3;
$polar: #f0f9fc;
$mercury: #e5e5e5;
$water: #d3e8fd;
$curious-blue: #2ba6cb;
$mine-shaft: #5c5757;

// Opera colors
$brown: #a52a2a;
$blue: #1e7ac2;
$firebrick: #b22222;
$green: #008000;
$gainsboro: #dcdcdc;
$indigo: #4b0082;
$khaki: #f0e68c;
$lemonchiffon: #fffacd;
$olive: #808000;
$orange: #ffa500;
$pink: #ffc0cb;
$red: #ff0000;
$sienna: #a0522d;
$silver: #c0c0c0;
$tan: #d2b48c;
$teal: #008080;
$violet: #ee82ee;
$yellow: #ffff00;

$color-transparent-white: rgba(255, 255, 255, 0.8);
