@import '../../global/utils/init';

.paragraphs-item-video {
    
  iframe {
    aspect-ratio: 16/9;
    height: auto;
    width: 100%;
  }
}
