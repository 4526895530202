// Import site utilities.
@import '../../global/utils/init';

article {

  .field-name-field-tags {
    margin-bottom: 80px;

    .field-label {
      background-color: $pacific-blue;
      color: $white;
      padding: 2px 10px;
      position: absolute;
    }

    .field-items {
      display: inline-block;

      .field-item {
        display: inline-block;
        margin: 0 15px 15px 0;

        &:first-child {
          margin-left: 90px;
        }

        a {
          color: $pacific-blue;
          border-bottom: 1px solid $gray;
          border-right: 1px solid $gray;
          padding: 5px 10px;
        }
      }
    }
  }

  .field-name-field-update-type {
    margin-bottom: 30px;
  }
}

//tags details page
.vocabulary-tags {

  .field-name-field-display-name {
    display: none;
  }
}

@include lg {

  article {

    .field-name-field-tags {
      margin-bottom: 35px;
    }
  }
}

@include md {

  article {

    .field-name-field-tags {
      margin-bottom: 20px;

      .field-items {

        .field-item {

          &:first-child {
            margin-left: 75px;
          }
        }
      }
    }
  }
}
