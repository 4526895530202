// Breakpoints.

$bp-under-xsm: 'max-width: 543px';
$bp-under-sm: 'max-width: 767px';
$bp-xsm: 544px;
$bp-sm: 768px;
$bp-md: 1024px;
$bp-lg: 1200px;
$bp-xl: 1540px;

@mixin breakpoint($viewport) {
  @if type-of($viewport) == number {
    @media screen and ( min-width: $viewport ) {
      @content;
    }
  }
  @else {
    @media screen and ( $viewport ) {
      @content;
    }
  }
}

// Media Screen
// Small tablets and large smartphones (landscape view)
$screen-sm-max: 550px;

// Small tablets (portrait view)
$screen-md-max: 767px;

// Tablets and small desktops
$screen-lg-max: 991px;

// Large tablets and desktops
$screen-xl-max: 1199px;
$screen-xxl-max: 1445px;

// Small devices
@mixin sm {
  @media (max-width: #{$screen-sm-max}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (max-width: #{$screen-md-max}) {
    @content;
  }
}
// Medium devices
@mixin min-md {
  @media (min-width: #{$screen-md-max}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (max-width: #{$screen-lg-max}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (max-width: #{$screen-xl-max}) {
    @content;
  }
}
@mixin xxl {
  @media (max-width: #{$screen-xxl-max}) {
    @content;
  }
}