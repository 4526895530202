// Import site utilities.
@import '../../global/utils/init';

.block-search-form {
  position: unset !important;
  margin: 0 !important;

  .search-box {

    > div {
      text-align: right;
    }

    .form-type-search {
      background: $water;
      display: inline-block;
      margin: 0;
      overflow: hidden;
      position: absolute;
      right: 0;
      height: 100%;
      top: 0;
      z-index: 1;

      input {
        background: transparent;
        border: 0;
        padding: 8px;
        width: 100%;
      }
    }

    .form-actions {
      margin: 0;
      position: absolute;
      right: 0;
      top: 4px;

      .fa-search {
        color: $periwinkle-gray;
        font-size: 20px;
        padding: 6px;
        position: relative;
        text-align: center;
        width: 30px;
        z-index: 1;
      }

      input {
        background: transparent;
        border: 0;
        box-shadow: none;
        cursor: pointer;
        height: 32px;
        margin: 0;
        padding: 0;
        position: absolute;
        right: 0;
        text-indent: 9999px;
        top: 0;
        width: 30px;
        z-index: 1;
      }

      &.typed {
        z-index: 0;
      }
    }
  }
}

.search-results {

  li {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

//search result page design
.l-wrapper {

  .search-form {
    margin-top: 100px;

    .form-actions {
      display: inline-block;

      input {
        background: $pacific-blue;
        color: $color-white;
        line-height: 32px;
      }
    }

    fieldset {

      .fieldset-title {
        padding: 0.5em 0.3em 0.3em 1.8em;
      }
    }
  }
}

@include lg {

  .block-search-form {
    position: absolute;
    width: 100%;
    top: 0;

    .search-box {

      .form-type-search {
        bottom: auto;
        position: fixed;
        top: 0;
        height: 40px;
        width: 100%;
        z-index: 99;

        input {
          padding: 8px;
          height: 100%;
        }
      }

      .form-actions {
        top: 0;
        right: unset;
        position: fixed;
        z-index: 99;
        left: 15px;

        .fa-search {
          font-size: 25px;
        }
      }
    }
  }

  .admin-bar {

    .block-search-form {

      .search-box {

        .form-type-search {
          top: 33px;
        }

        .form-actions {
          top: 33px;
        }
      }
    }
  }
}

@include md {

  .block-search-form {

    .search-box {

      .form-actions {
        top: 2px;

        .fa-search {
          font-size: 20px;
        }
      }
    }
  }

  //search result page design

  .l-wrapper {

    .search-form {
      margin-top: 30px;

      .form-search {
        margin-top: 10px;
      }

      .form-actions {

        .form-submit {
          margin-top: 15px;
        }
      }

      fieldset {

        .form-actions {
          margin-top: 0;

          .form-submit {
            margin-top: 0;
          }
        }
      }
    }
  }

  ol {

    &.search-results {
      padding-left: 20px;

      .title {
        font-size: 28px;
        line-height: 32px;
      }
    }
  }
}
