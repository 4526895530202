// Import site utilities.
@import '../../global/utils/init';

.field-name-field-partners-longer {
  background: $polar;
  border: 1px solid $mercury;
  padding: 50px 55px 60px;
  border-radius: 2px;

  .field-label {
    color: $lochmara;
    font-size: 26px;
    font-family: $font-franklin-demi;
    line-height: 30px;
    float: none;
  }

  .field-items {
    float: none;
    padding-top: 5px;
    word-break: break-word;

    p {
      
      &:last-child {
        margin: 0;
      }
    }
  }
}

@include md {

  .field-name-field-partners-longer {
    padding: 35px 35px 40px;

    .field-label {
      font-size: 20px;
      line-height: 24px;
    }
  }
}
