// Import site utilities.
@import '../../global/utils/init';

.l-header {
  // box-shadow: 0 3px 6px #00000029;
  left: 0;
  padding: 41px 0 32px;
  position: fixed;
  top: 0;
  transition: 0.5s;
  width: 100%;
  z-index: 99;

  &.scroll {
    top: -140px;
  }

  &.no-scroll {
    top: 0;
  }

  .l-header-inner {
    display: flex;
    justify-content: space-between;

    &::after {
      display: none;
    }

    .site-logo {
      margin-top: 20px;
    }
  }

  .block-system-header {
    flex-basis: 200px;
    margin-top: auto;
    min-height: unset;

    .block--inner-wrapper {
      padding: 0;
    }

    .block-content {
      display: block;
    }

    .header-identity-wrapper {
      display: block;
      width: 100%;
    }

    .header-site-name-wrapper {
      display: block;
      min-height: unset;
      width: 100%;

      .header-logo-wrapper {
        height: auto;
        line-height: 0;
        margin: 0;
      }

      a {
        display: block;
        margin-top: auto;
        width: 100%;
      }

      img {
        padding: 0;
        width: 100%;
        max-height: 60px;
        height: 60px;
      }
    }
  }

  .block-system-main-menu {
    flex-basis: calc(100% - 500px);
    line-height: 0;
    text-align: center;

    > .block--inner-wrapper {
      margin-top: 8px;
    }

    .menu {
      display: inline-block;
    }
  }

  .block-search-form {
    margin-top: 45px;

    .block--inner-wrapper {
      padding: 0 15px;
    }
  }

  .block-menu-menu-give-menu {
    flex-basis: 100px;
    margin-top: auto;
  }

  @include xl {

    .block-system-main-menu {
      flex-basis: calc(100% - 400px);
    }
  }

  @include lg {

    .l-header-inner {
      flex-direction: column;
      position: relative;

      > .block {
        flex-basis: auto;
        margin: 0;

        &.block-system-header {
          order: 1;
        }

        &.block-menu-menu-header-top-menu {
          order: 0;
          margin-top: 20px;
        }

        &.block-system-main-menu {
          order: 2;

          .menu-toggle-button {
            display: inline-block;
          }
        }

        &.block-search-form {
          order: 3;
          position: absolute;
          right: 15px;
          top: 0;

          .container {
            padding: 0;
          }
        }

        &.block-menu-menu-give-menu {
          order: 4;
        }
      }

      .block-system-header {
        margin: 0 auto;

        .header-site-name-wrapper {

          .header-logo-wrapper {
            height: auto;
          }
        }
      }

      .block-system-main-menu {
        bottom: 0;
        left: 0;
        position: absolute;
      }

      .block-menu-menu-give-menu {
        bottom: 0;
        position: absolute;
        right: 20px;
      }
    }
  }

  @include md {

    .l-header-inner {

      .block-system-header {

        .header-site-name-wrapper {

          img {
            height: auto;
            width: 144px;
          }
        }
      }
    }
  }
}

.admin-bar {

  .l-header {
    top: 33px;

    &.no-scroll {
      top: 33px;
    }

    &.scroll {
      top: -140px;
    }
  }
}
