@import '../../global/utils/init';


.block-views-updates-block-2 {

  .block-title {
    font-size: 36px;
    line-height: 74px;
  }
}

//updates and articles list
.view-updates-and-articles {
  margin-bottom: 90px;

  .block-title {
    margin-top: 30px;
  }
}

//update's post list on River Corridor Land page
.update-post,
.term-post {

  .views-row {
    border-bottom: 1px solid $black;
    margin: 0;
    padding: 35px 0 50px;

    &:first-child {
      border-top: 1px solid $black;
    }
  }

  .post-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 50px;

    .post-title {
      padding-bottom: 25px;

      a {
        color: $lochmara;
        font-size: 30px;
        font-family: $font-franklin-demi;
        line-height: 32px;
        text-decoration: none;
      }
    }

    .post-body {
      font-family: $font-franklin-book;
      font-size: 18px;
      line-height: 28px;

      .views-more-link {
        color: $lochmara;
        display: inline;
        font-family: $font-franklin-demi;
        text-decoration: none;
      }

      p {
        display: inline;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 0;

        &:last-child {

          a {

            &:last-child {
              display: none;
            }
          }
        }
      }
    }

    .post-author,
    .post-date {
      color: $midnight-blue;
      font-size: 18px;
      font-family: $font-franklin-book;
      line-height: 24px;
    }

    .post-date {
      padding-bottom: 28px;
    }
  }

  img {
    margin-top: 5px;
    max-width: 270px;
  }

  .post-type {
    display: none;
  }
}


//updates on program pages
.view-updates {

  &.full-width {
    width: 1200px;
    margin-left: -120px;
  }
}

//responsive design

@include xl {

  .view-updates {

    &.full-width {
      width: 100%;
      margin-left: 0;
    }
  }
}

@include md {

  .view-updates-and-articles {
    margin-bottom: 30px;
  }

  .update-post,
  .term-post {

    .views-row {
      padding: 25px 0;

      .views-field {
        margin: 0;
      }

      .post-wrapper {
        display: block;
        width: 100%;

        .post-title {
          padding-bottom: 20px;

          a {
            font-size: 20px;
            line-height: 22px;
          }
        }

        .post-author,
        .post-date,
        .post-body {
          font-size: 16px;
          line-height: 22px;
        }

        .post-date,
        .post-body {
          padding-bottom: 20px;
        }

        .post-img {

          img {
            margin: 0;
            max-width: 210px;
          }
        }
      }
    }
  }
}

