// Import site utilities.
@import '../../global/utils/init';

.l-wrapper {

  .block {
    margin-bottom: 100px;

    &.stretch-of-the-river-title {
      margin: 0;
    }
  }
}

.block.block-system-title {
  margin-bottom: 0;
}

.front div.flexible-row div.block,
.front .l-wrapper .block {
  padding: 0;
}

//newletter sign up block global design 
.block-sign-up {
  min-height: unset !important;
  text-align: center;

  p {
    font-size: 32px;
    font-family: $font-franklin-book;
    line-height: 30px;

    a {
      background: $pacific-blue;
      color: $white !important;
      display: inline-block;
      font-family: $font-franklin-demi;
      font-size: 32px;
      line-height: 30px;
      margin-left: 30px;
      padding: 20px 55px;
      text-decoration: none;
      text-transform: uppercase;
    }
  }
}

h2.block-title,
.block-title {
  color: $color-bg-black;
  font-size: 48px;
  font-family: $font-franklin-demi;
  font-weight: bold;
  line-height: 48px;
  margin-bottom: 30px;
  text-align: center;
  text-transform: uppercase;
}

//remove frontpage update mosaic block background color
.block-views-updates-block-1 {
  background-color: transparent !important;
}

//author and date block design

.paragraphs-item-author-date  {
  margin-bottom: 30px !important;

  .views-row {
    margin-bottom: 0;
  }

  .view-author-and-date {

    .views-field {
      margin: 0;

      .field-content {
        font-size: 22px;
        line-height: 30px;
      }
    }
  }
}

//media-hits block design
.view-media-hits {

  .views-row {
    border-top: 1px solid $gray;
    padding-top: 30px;
    margin-bottom: 30px;

    &.last {
      border-bottom: 1px solid $gray;
    }
  }

  .date-publication-wrapper {
    color: $dove-gray;
    text-transform: uppercase;
  }

  .media-title {
    font-family: $font-franklin-demi;
    font-size: 26px;
    line-height: 30px;

    a {
      color: $pacific-blue;
    }
  }

  .media-summary {
    padding-top: 30px;
  }
}

//contact us custom block design

.contact-us {
  margin: 72px 0 0;
}

@include lg {

  .l-wrapper {

    .block {
      margin-bottom: 50px;
    }
  }

  h2.block-title,
  .block-title {
    font-size: 32px;
    line-height: 45px;
    margin-bottom: 20px;
  }

  //newletter sign up block global design 
  .block-sign-up {

    p {
      font-size: 28px;
      line-height: 30px;
      margin-bottom: 0;

      a {
        font-size: 28px;
        margin: 20px 0 0;
      }
    }
  }
}

.custom-error-message-block {
  padding-top: 50px;
}

@include md {

  .l-wrapper {

    .block {
      margin-bottom: 30px;
    }
  }

  h2.block-title,
  .block-title {
    font-size: 22px;
    line-height: 24px;
    margin-bottom: 20px;
  }

  .block-sign-up {

    .container {
      padding-left: 40px;
      padding-right: 40px;
    }

    p {
      font-size: 20px;
      line-height: 26px;
      margin-bottom: 0;

      a {
        display: block;
        font-size: 20px;
        max-width: fit-content;
        line-height: 30px;
        margin: 20px auto 0;
        padding: 12px 44px;
      }
    }
  }

  .paragraphs-item-author-date  {
  
    .view-author-and-date {
  
      .views-field {
  
        .field-content {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }

  .view-media-hits {

    .media-title {
      font-size: 20px;
      line-height: 22px;
    }
  }

  .contact-us {
    margin: 30px 0 0;
  }

  .custom-error-message-block {
    padding-top: 30px;
  }
}
