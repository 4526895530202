@import '../../global/utils/init';

.profile {

  h2 {
    border-bottom: 0;
    margin: 0;
    margin-bottom: 35px;
  }

  .name {
    font-size: 60px;
    font-family: $font-franklin-demi;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 60px;
    text-align: center;
    gap: 15px;
    margin: 62px 0 50px;

    .field {
      display: inline-block;
    }
  }

  .picture {

    .field-name-field-profile-picture {
      display: flex;
      justify-content: center;
      float: none;
      margin: 0;
      padding-bottom: 25px;
    }
  }
}

@include md {

  .profile {

    .name {
      font-size: 30px;
      line-height: 30px;
      margin: 30px 0;
      gap: 10px;
    }
  }
}
