// Import site utilities.
@import '../../global/utils/init';

.block-menu-menu-give-menu {

  .block--inner-wrapper {
    padding: 0;
  }

  .menu {

    > li {
      width: 100%;

      > a {
        background: $blue-chill;
        color: $white;
        display: block;
        font-size: 24px;
        font-family: $font-franklin-demi;
        line-height: 49px;
        padding: 5px 15px !important;
        text-align: center;
        text-transform: capitalize;
      }

      > ul {
        background: $color-white;
        padding: 40px 25px 15px;
        width: auto;

        li {

          a {
            padding: 0 !important;
            white-space: nowrap;
          }

          &:last-child {
            padding-bottom: 0;
          }
        }
      }
    }
  }

  @include xl {

    .menu {

      > li {

        > ul {
          left: auto;
          position: absolute;
          right: 0;
        }
      }
    }
  }

  @include lg {

    .menu {

      > li {

        > a {
          font-size: 18px;
          line-height: 40px;
        }

        > ul {
          padding: 20px 25px 15px;
          right: -20px;
        }
      }
    }
  }

  @include md {

    .menu {

      > li {

        > a {
          font-size: 16px;
          line-height: 33px;
          padding: 0 10px !important;
          pointer-events: none;
        }
      }
    }
  }
}
