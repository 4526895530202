// Import site utilities.
@import '../../global/utils/init';

.block-menu-menu-staff,
.block-menu-menu-mission-and-vission {

  .block-title {
    display: none;
  }

  .block--inner-wrapper {
    padding: 0;
  }

  .menu {
    display: flex;
    margin: 0;
    padding: 0;
    justify-content: center;
    border-bottom: 1px solid $color-text-black;


    li {
      align-items: center;
      border: 1px solid $silver-sand;
      border-bottom: 1px solid $color-text-black;
      display: flex;
      min-width: 193px;
      flex-basis: 20%;
      padding: 0;
      text-align: center;
      margin-bottom: -1px;
      background: #fff;

      &::marker {
        content: '';
      }

      a {
        color: $silver-sand;
        display: inline-block;
        font-family: $font-franklin-demi;
        font-size: 24px;
        line-height: 24px;
        padding: 30px 60px !important;
        text-decoration: none;
        text-transform: capitalize;
        width: 100%;

        &.active {
          color: $color-text-black;
        }
      }

      &.active-trail {
        border-bottom: 0;
        border-color: $color-text-black;
      }
    }
  }
}

@include md {

  .block-menu-menu-staff,
  .block-menu-menu-mission-and-vission {

    .menu {

      li {
        min-width: unset;

        a {
          font-size: 20px;
          padding: 20px !important;
        }
      }
    }
  }

  .block-menu-menu-mission-and-vission {

    .menu {
      flex-direction: column;

      li {
        border-bottom: 1px solid $silver-sand;

        &.active-trail {
          border-color: $silver-sand;
        }

        &:last-child {
          border-bottom: 1px solid $silver-sand;
        }
      }
    }
  }
}
