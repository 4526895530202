// Import site utilities.
@import '../../global/utils/init';

.block-gtranslate-gtranslate {
  display: flex;
  align-items: center;

  .block--inner-wrapper {
    padding: 0 0 0 30px;
  }

  select {
    border: 2px solid $blue-chill;
    padding: 2px;

    &:focus-visible {
      outline: none;
    }
  }
}
